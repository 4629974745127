import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon, SweetAlertPosition } from 'sweetalert2';


@Injectable({providedIn: 'root'})
export class ToastAlertService {

  /**
   * Show Toast
   * @param type
   * @param title
   * @param side
   * @param time
   * @param showProgressBar
   */
  showToast(title: string, type: SweetAlertIcon = "success", side: SweetAlertPosition = 'top-end', time: number = 1500, showProgressBar: boolean = true){
    const Toast = Swal.mixin({
      toast: true,
      position: side ? side : 'top-end',
      showConfirmButton: false,
      timer: time,
      timerProgressBar: showProgressBar ? showProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    Toast.fire({
      icon: type,
      title: title
    })
  }

}
