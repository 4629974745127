<div class="d-flex justify-content-center flex-column align-items-center my-20">

  <img alt="Logo" [lazyLoad]="'assets/images/logos/logo-2.svg' | imagekitUrlAppender" [useSrcset]="true" class="logo-default h-25px h-lg-50px w-400px" />


  <div class="d-flex flex-column py-12 text-center position-relative h-75" *ngIf="lottieOptions">
    <div class=" d-flex justify-content-center">
      <ng-lottie class="top-0 z-index-3 w-250px" [options]="lottieOptions"></ng-lottie>
    </div>
  </div>


  <h1 class="text-center mt-20 text-black fs-1">
    {{message}}
  </h1>


  <a class="btn btn-gradient text-white ls-2 text-uppercase fw-boldest my-20 btn-lg" routerLink="/">
    Back to home
  </a>
</div>
