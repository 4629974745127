import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Auth, IAccessTokenDetails } from 'src/app/core/domain';
import { StorageService } from '../utils/storage.service';
import decode from 'jwt-decode';
import { ACCOUNT_TYPE_TO_DASHBOARD_UTL } from '../constants/navlinks';


/**
 * Used for login/ signup type of page where only non logged in users should be able to go
 */
@Injectable({
  providedIn: 'root'
})
export class NonAuthGuard implements CanActivate {
  constructor(
    private storageService: StorageService,
    private router: Router,
  ) { }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user: Auth = this.storageService.getObject(StorageService.storageKeys.user);
    if (!user) {
      return true;
    }

    let tokenDetails: IAccessTokenDetails;
    try {
      tokenDetails = decode(user.accessToken);

      if ((Date.now() >= tokenDetails.exp * 1000) || !tokenDetails.accountType) {
        throw new Error("token expired, or account type not found");
      }
    } catch (error) {
      // in case a broken token found
      // TODO: also clear state
      this.storageService.clear();
      return true;
    }

    if (ACCOUNT_TYPE_TO_DASHBOARD_UTL[tokenDetails.accountType]) {
      const redirectUrl = ACCOUNT_TYPE_TO_DASHBOARD_UTL[tokenDetails.accountType];
      this.router.navigate([redirectUrl])
      return false;
    }

    return true;
  }

}
