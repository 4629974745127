import {
    ActionReducerMap,
} from "@ngrx/store";
import * as fromAuth from "./auth/auth.reducer";
import * as fromFaq from "./faq/faq.reducer";
import * as fromStartUp from "./startup/startup.reducer";
import * as fromGlossary from "./glossary/glossary.reducer";
import * as fromGlobal from "./global/global.reducer";
import * as fromInvestorOrg from "./investors-organization-info/investors-organization-info.reducer";
import * as fromSign from "./sign-up/sign-up.reducer"
import * as fromProfile from "./profile/profile.reducer"
import * as fromInvestorDashboard from './investor-dashboard/investor.dashboard.reducer'
import * as fromStartupDashboard from './startup-dashboard/startup.dashboard.reducer'
import * as fromCorporateDashboard from './corporate-dashboard/corporate.dashboard.reducer'
import * as fromCompare from './compare/compare.reducer'
import * as fromStartupCompare from './startup-compare/startup.compare.reducer'
import * as fromInvestorCompare from './investor-compare/investor.compare.reducer'
import * as fromStartUpKit from './start-up-kit/start-up-kit.reducer';
import * as fromPitchDeck from './pitch-deck/pitch-deck.reducer';
import * as fromNotifications from './notifications/notifications.reducer';
import * as fromCorporate from './corporate-info/corporate-info.reducer';
import * as fromPartners from './partners/partners.reducer';
import * as fromPartnersInfo from './partner-info/partner-info.reducer';
import * as fromAds from './ads/ads.reducer';
import * as fromJobs from './jobs/jobs.reducer';
import * as fromChallenges from './challenges/challenges.reducer';

export interface AppState {
    auth: fromAuth.AuthState;
    faq: fromFaq.FaqState;
    startup: fromStartUp.StartUpState,
    glossary: fromGlossary.GlossaryState,
    global: fromGlobal.GlobalState
    investorsOrg: fromInvestorOrg.InvestorsOrgInfoState
    signupSendOtp: fromSign.SignUpSendOtpState;
    signupVerifyOtp: fromSign.SignUpVerifyOtpState;
    signupRegister: fromSign.RegisterState;
    profile: fromProfile.ProfileState;
    investorDashboard: fromInvestorDashboard.InvestorDashboardState;
    compare: fromCompare.CompareState
    startupDashboard: fromStartupDashboard.StartupDashboardState;
    startupCompare: fromStartupCompare.StartupCompareState,
    investorCompare: fromInvestorCompare.InvestorCompareState,
    startUpKit: fromStartUpKit.StartUpKitState,
    pitch: fromPitchDeck.PitchState,
    notifications: fromNotifications.NotificationsState,
    corporate: fromCorporate.CorporateInfoState;
    corporateDashboard: fromCorporateDashboard.CorporateDashboardState;
    partners: fromPartners.PartnersState;
    partner: fromPartnersInfo.PartnerInfoState;
    ads: fromAds.AdsState;
    jobs: fromJobs.JobsState;
    challenges: fromChallenges.ChallengesState;
}

export const reducers: ActionReducerMap<AppState, any> = {
    auth: fromAuth.authReducer,
    faq: fromFaq.faqReducer,
    startup: fromStartUp.startUpReducer,
    glossary: fromGlossary.glossaryReducer,
    global: fromGlobal.globalReducer,
    investorsOrg: fromInvestorOrg.investorsOrgReducer,
    signupSendOtp: fromSign.signUpSendOtpReducer,
    signupVerifyOtp: fromSign.signUpVerifyOtpReducer,
    signupRegister: fromSign.signUpRegisterReducer,
    profile: fromProfile.profileReducer,
    investorDashboard: fromInvestorDashboard.investorDashboardReducer,
    startupDashboard: fromStartupDashboard.startUpDashboardReducer,
    compare: fromCompare.compareReducer,
    startupCompare: fromStartupCompare.startUpCompareReducer,
    investorCompare: fromInvestorCompare.investorCompareReducer,
    startUpKit: fromStartUpKit.startUpKitReducer,
    pitch: fromPitchDeck.pitchReducer,
    notifications: fromNotifications.notificationsReducer,
    corporate: fromCorporate.corporateReducer,
    corporateDashboard: fromCorporateDashboard.corporateDashboardReducer,
    partners: fromPartners.startUpDashboardReducer,
    partner: fromPartnersInfo.partnerReducer,
    ads: fromAds.adsReducer,
    jobs: fromJobs.jobsReducer,
    challenges: fromChallenges.challengesReducer
};



