import {
  HttpClient,
  HttpErrorResponse,
  HttpStatusCode
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  Observable,
  throwError
} from "rxjs";
import {
  catchError,
  map
} from "rxjs/operators";
import { ToastAlertService } from "src/app/shared/utils/toast.service";
import { IProductStages } from "../domain/product-information.model";
import { IPublicPageContent } from "../domain/public.models";
import { IDropdownOptions, IGlobalSettings } from "../domain/settings.model";
import { ApiEndpointService } from "./api-endpoint.service";

@Injectable({
  providedIn: "root"
})
export class PublicApiService {
  constructor(private http: HttpClient, private toastService: ToastAlertService, private router: Router) { }

  public fetchGlobalSettings(): Observable<IGlobalSettings> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.GLOBAL_SETTINGS);
    return this.http.get(url).pipe(
      map((response: any): any => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching global settings', 'error');
        return throwError(() => fault);
      })
    );
  }

  /**
   * You can use this API to fetch multiple information at once. http://localhost:3000/api/v1/public/global/custom/industries,technologies
   * after custom:  just pass the last param in commas
   */
  public fetchCustomPublicData(customType: string): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.PUBLIC_CUSTOM + customType);
    return this.http.get(url).pipe(
      map((response: any): any => {

        if (response.data?.industries && response.data?.industries?.length) {
          const indexOfHorizontal = response.data.industries.findIndex(e => e.name.toLowerCase() === "horizontal");
          if (indexOfHorizontal >= 0) {
            const d = response.data.industries.splice(indexOfHorizontal, 1);
            response.data.industries.unshift(d[0]);
          }
        }

        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        return throwError(() => fault);
      })
    );
  }

  public fetchProductStages(): Observable<IProductStages[]> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.PRODUCT_STAGES);
    return this.http.get(url).pipe(
      map((response: any): any => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        return throwError(() => fault);
      })
    );
  }

  public fetchFundingStages(): Observable<IProductStages[]> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.FUNDING_STAGES);
    return this.http.get(url).pipe(
      map((response: any): any => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching funding stages', 'error');
        return throwError(() => fault);
      })
    );
  }

  public fetchBusinessModels(): Observable<IProductStages[]> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.BUSINESS_MODELS);
    return this.http.get(url).pipe(
      map((response: any): any => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching funding stages', 'error');
        return throwError(() => fault);
      })
    );
  }
  public fetchDeliveryModels(): Observable<IDropdownOptions[]> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.DELIVERY_MODELS);
    return this.http.get(url).pipe(
      map((response: any): any => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching funding stages', 'error');
        return throwError(() => fault);
      })
    );
  }
  public fetchMaturityStage(): Observable<IDropdownOptions[]> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MATURITY_STAGES);
    return this.http.get(url).pipe(
      map((response: any): any => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching funding stages', 'error');
        return throwError(() => fault);
      })
    );
  }

  public fetchStartUpSearchPageOptions(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.STARTUP_SEARCH_OPTIONS);
    return this.http.get(url).pipe(
      map((response: any): any => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching search options', 'error');
        return throwError(() => fault);
      })
    );
  }

  public fetchInvestorSearchPageOptions(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.INVESTOR_SEARCH_OPTIONS);
    return this.http.get(url).pipe(
      map((response: any): any => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching search options', 'error');
        return throwError(() => fault);
      })
    );
  }

  redirectToPageNotFound(status: number): void {
    if (status === HttpStatusCode.NotFound) {
      this.router.navigate(['/errors/404']);
    }
  }

  public fetchStartUpPublicProfileData(startUpUUID: string): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.STARTUP_PUBLIC_PROFILE + startUpUUID);
    return this.http.get(url).pipe(
      map((response: any): any => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching startup public profile data', 'error');
        this.redirectToPageNotFound(fault.status);
        return throwError(() => fault);
      })
    );
  }

  public fetchInvestorsPublicProfileData(investorUUID: string): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.INVESTORS_PUBLIC_PROFILE + investorUUID);
    return this.http.get(url).pipe(
      map((response: any): any => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching investor public profile data', 'error');
        this.redirectToPageNotFound(fault.status);
        return throwError(() => fault);
      })
    );
  }

  public fetchPagesById(pageId: string): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.PAGES + '/' + pageId);
    return this.http.get(url).pipe(
      map((response: any): any => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching search options', 'error');
        return throwError(() => fault);
      })
    );
  }

  public fetchAllPages(): Observable<IPublicPageContent[]> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.PAGES);
    return this.http.get(url).pipe(
      map((response: any): any => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching pages', 'error');
        return throwError(() => fault);
      })
    );
  }

  public fetchStartUpKit(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.STARTUP_KIT);
    return this.http.get(url).pipe(
      map((response: any): any => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching search options', 'error');
        return throwError(() => fault);
      })
    );
  }

  public fetchStartUpKitDetail(serviceId: string): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.STARTUP_KIT_DETAIL + serviceId);
    return this.http.get(url).pipe(
      map((response: any): any => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching search options', 'error');
        return throwError(() => fault);
      })
    );
  }

  public postStartUpKit(serviceId: string): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.STARTUP_KIT_DETAIL + serviceId);
    return this.http.post(url, {}).pipe(
      map((response: any): any => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching search options', 'error');
        return throwError(() => fault);
      })
    );
  }
  public checkAppliedForStartupKit(serviceId: string): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.STARTUP_KIT_DETAIL + serviceId)+'/check';
    return this.http.get(url).pipe(
      map((response: any): any => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching search options', 'error');
        return throwError(() => fault);
      })
    );
  }


  public fetchGlobalBanner(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.GLOBAL_BANNERS);
    return this.http.get(url).pipe(
      map((response: any): any => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching search options', 'error');
        return throwError(() => fault);
      })
    );
  }

  public fetchGlobalTestimonials(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.GLOBAL_TESTIMONIALS);
    return this.http.get(url).pipe(
      map((response: any): any => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching search options', 'error');
        return throwError(() => fault);
      })
    );
  }

}
