import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'imagekitUrlAppender'
})
export class ImagekitUrlAppenderPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): string {
    return environment.imageKitBaseUrl + value
  }

}
