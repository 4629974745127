import { NotificationsActions, NotificationsActionTypes } from "./notifications.action";
import { NotificationsCount } from "./notifications.model";

export interface NotificationsState {
  count: NotificationsCount,
  error: string | null
}

export const initialState: NotificationsState = {
  count: {
    pendingConnectionCount: 0,
    sentConnectionCount: 0,
    unreadNotificationCount: 0,
    unreadMessageCount: 0
  },
  error: ""
};

function setNotificationsSuccess(state: NotificationsState = initialState, data: NotificationsCount): NotificationsState {
  return {
    ...state,
    count: data,
    error: ""
  };
}

function setNotificationsFault(state: NotificationsState = initialState, data: string): NotificationsState {
  return {
    ...state,
    count: null,
    error: data

  };
}

function markAllReadNotificationsSuccess(state: NotificationsState = initialState, data: NotificationsCount): NotificationsState {
  return {
    ...state,
    count: data,
    error: ""
  };
}

function markAllReadNotificationsFault(state: NotificationsState = initialState, data: string): NotificationsState {
  return {
    ...state,
    count: null,
    error: data

  };
}

export function notificationsReducer(state: NotificationsState = initialState, action: NotificationsActions): NotificationsState {
  switch (action.type) {
    case NotificationsActionTypes.SetNotificationsCountSuccess:
      return setNotificationsSuccess(state, action.payload);
    case NotificationsActionTypes.SetNotificationsCountFault:
      return setNotificationsFault(state, action.payload);
    case NotificationsActionTypes.MarkAllReadNotificationsSuccess:
      return markAllReadNotificationsSuccess(state, action.payload);
    case NotificationsActionTypes.MarkAllReadNotificationsFault:
      return markAllReadNotificationsFault(state, action.payload);
    default:
      return state;
  }
}


