import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  Actions,
  createEffect,
  ofType
} from "@ngrx/effects";
import {
  of
} from "rxjs";
import {
  catchError,
  exhaustMap,
  map,
} from "rxjs/operators";
import { NotificationsService } from "../../service/notifications.service";
import * as action from "./notifications.action";
import { NotificationsCount } from "./notifications.model";

@Injectable()
export class NotificationsEffect {

  setNotificationsCount$ = createEffect(() => this.actions$.pipe(
    ofType<action.NotificationsActions>(action.NotificationsActionTypes.SetNotificationsCount),
    exhaustMap(() =>
      this.notificationsService.fetchNotificationCount().pipe(
        map((data: NotificationsCount) => {
          return new action.SetNotificationsCountSuccess(data)
        }),
        catchError((err: HttpErrorResponse) => of(new action.SetNotificationsCountFault(err.message)))
      )
    )
  ));

  markAllReadNotifications$ = createEffect(() => this.actions$.pipe(
    ofType<action.NotificationsActions>(action.NotificationsActionTypes.MarkAllReadNotifications),
    exhaustMap(() =>
      this.notificationsService.markAllReadNotifications().pipe(
        map((res: any) => {
          if (res) {
            const data = {
              pendingConnectionCount: 0,
              unreadNotificationCount: 0,
              unreadMessageCount: 0,
              sentConnectionCount: 0
            }
            return new action.MarkAllReadNotificationsSuccess(data)
          }
        }),
        catchError((err: HttpErrorResponse) => of(new action.MarkAllReadNotificationsFault(err.message)))
      )
    )
  ));

  constructor(private actions$: Actions, private notificationsService: NotificationsService) {
  }
}
