import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragAndDropFileSelectDirective } from './directives/drag-and-drop-file-select.directive';
import { QuillModule } from "ngx-quill";
import { NgxUiLoaderConfig, NgxUiLoaderModule } from "ngx-ui-loader";

import { ExtractIndustriesPipe } from './pipes/extract-industries.pipe';
import { DashboardCommonComponent } from './common-components/startup-investor-dashboard-common/dashboard-common.component';
import { DashboardCommonCalenderComponent } from './common-components/startup-investor-dashboard-common-calender/dashboard-common-calender.component';
import { NgbAlertModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StartupInvestorMonthlyInvestmentComponent } from './common-components/startup-investor-monthly-investment/startup-investor-monthly-investment.component';
import { AvatarModule } from 'ngx-avatars';
import { StartupInvestorDashboardProfileComponent } from './common-components/startup-investor-dashboard-profile/startup-investor-dashboard-profile.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { StartupInvestorCompareComponent } from './common-components/startup-investor-compare/startup-investor-compare.component';
import { RouterModule } from '@angular/router';
import { StartupCompareCardComponent } from './common-components/startup-investor-compare/startup-compare-card/startup-compare-card.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CongratulationsAnimationComponent } from './common-components/congratulations-animation/congratulations-animation.component';
import player from 'lottie-web';
import { LottieModule } from 'ngx-lottie';
import { UserProfileMenuComponent } from './common-components/user-profile-menu/user-profile-menu.component';
import { OffcanvasSidebarComponent } from './common-components/offcanvas-sidebar/offcanvas-sidebar.component';
import { PageNotFoundComponent } from './common-components/page-not-found/page-not-found.component';
import { ComingSoonComponent } from './common-components/coming-soon/coming-soon.component';
import { NoDataComponent } from './common-components/no-data/no-data.component';
import { StartupRaiseFundSwitchComponent } from './common-components/startup-raise-fund-switch/startup-raise-fund-switch.component';
import { InvestorProvidingFundsSwitchComponent } from './common-components/investor-providing-funds-switch/investor-providing-funds-switch.component';
import { AddMeetingButtonComponent } from './common-components/add-meeting-button/add-meeting-button.component';
import { AddMeetingModalComponent } from './common-components/add-meeting-button/add-meeting-modal/add-meeting-modal.component';
import { OurInvestorsPortfolioComponent } from './common-components/our-investors-portfolio/our-investors-portfolio.component';
import { PipesModule } from './pipes/pipes.module';
import { CorporateProfileCompletenessComponent } from './common-components/corporate-profile-completeness/corporate-profile-completeness.component';
import { ConnectionCountComponent } from './common-components/connection-count/connection-count.component';
import { AddTeamButtonComponent } from './common-components/add-team-button/add-team-button.component';
import { OurPartnerComponent } from './common-components/our-partner/our-partner.component';
import { AdViewerModule } from '../modules/ad-viewer/ad-viewer.module';
import { AcceptConnectionModalComponent } from './common-components/accept-connection-modal/accept-connection-modal.component';
import { RejectConnectionModalComponent } from './common-components/reject-connection-modal/reject-connection-modal.component';
import { MultiSelectDropdownModule } from './common-components/multi-select-dropdown/multi-select-dropdown.module';
import { RejectCandidateModalComponent } from './common-components/reject-candidate-modal/reject-candidate-modal.component';
import { CalenderModule } from '../modules/calender/calender.module';
import { EditMeetingComponent } from '../modules/calender/calender-component/edit-meeting-event/edit-meeting-event.component';
import { DeleteMeetingComponent } from '../modules/calender/calender-component/delete-meeting-event/delete-meeting-event.component';
import { MeetingDetailsModalComponent } from '../modules/calender/calender-component/meeting-details-modal/meeting-details-modal.component';
import { RecommendedInvestorsComponent } from './common-components/recommended-investors/recommended-investors.component';
import { InvestorsSearchCardModule } from '../modules/search/pages/investors/components/investors-search-card/investors-search-card.module';
import { RecommendedStartupsComponent } from './common-components/recommended-startups/recommended-startups.component';
import { StartupSearchCardModule } from '../modules/search/pages/startups/components/startup-search-card/startup-search-card.module';
import { SwiperModule } from "swiper/angular";
import { DashboardRecentChallengesComponent } from './common-components/dashboard-recent-challenges/dashboard-recent-challenges.component';
import { DashboardRecentJobApplicationsComponent } from './common-components/dashboard-recent-job-applications/dashboard-recent-job-applications.component';
// import { ChallengeApplicationModalComponent } from './common-components/challenge-application-modal/challenge-application-modal.component';

export function playerFactory() {
  return player;
}

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  // bgsColor: "red",
  // bgsPosition: POSITION.bottomCenter,
  // bgsSize: 40,
  // bgsType: SPINNER.rectangleBounce, // background spinner type
  // fgsType: SPINNER.chasingDots, // foreground spinner type
  // pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  // pbThickness: 5, // progress bar thickness
  fgsColor: '#e9843e',
  hasProgressBar: false
};

@NgModule({
  declarations: [
    DragAndDropFileSelectDirective,
    ExtractIndustriesPipe,
    DashboardCommonComponent,
    DashboardCommonCalenderComponent,
    ExtractIndustriesPipe,
    StartupInvestorMonthlyInvestmentComponent,
    StartupInvestorDashboardProfileComponent,
    StartupInvestorCompareComponent,
    StartupCompareCardComponent,
    CongratulationsAnimationComponent,
    UserProfileMenuComponent,
    PageNotFoundComponent,
    ComingSoonComponent,
    OffcanvasSidebarComponent,
    NoDataComponent,
    StartupRaiseFundSwitchComponent,
    InvestorProvidingFundsSwitchComponent,
    AddMeetingButtonComponent,
    AddMeetingModalComponent,
    OurInvestorsPortfolioComponent,
    CorporateProfileCompletenessComponent,
    ConnectionCountComponent,
    AddTeamButtonComponent,
    OurPartnerComponent,
    AcceptConnectionModalComponent,
    RejectConnectionModalComponent,
    RejectCandidateModalComponent,

    EditMeetingComponent,
    DeleteMeetingComponent,
    MeetingDetailsModalComponent,
    RecommendedInvestorsComponent,
    RecommendedStartupsComponent,
    DashboardRecentChallengesComponent,
    DashboardRecentJobApplicationsComponent,
    // ChallengeApplicationModalComponent
  ],
  imports: [
    CommonModule,
    QuillModule,
    NgbModule,
    NgApexchartsModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgbAlertModule,
    AvatarModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    LottieModule.forRoot({ player: playerFactory }),
    PipesModule,
    AdViewerModule,
    MultiSelectDropdownModule,
    InvestorsSearchCardModule,
    StartupSearchCardModule,
    SwiperModule
    // CalenderModule
  ],
  exports: [
    DragAndDropFileSelectDirective,
    ExtractIndustriesPipe,
    DashboardCommonComponent,
    DashboardCommonCalenderComponent,
    StartupInvestorMonthlyInvestmentComponent,
    StartupInvestorDashboardProfileComponent,
    StartupInvestorCompareComponent,
    QuillModule,
    NgxUiLoaderModule,
    CongratulationsAnimationComponent,
    UserProfileMenuComponent,
    OffcanvasSidebarComponent,
    NoDataComponent,
    StartupRaiseFundSwitchComponent,
    InvestorProvidingFundsSwitchComponent,
    AddMeetingButtonComponent,
    OurInvestorsPortfolioComponent,
    CorporateProfileCompletenessComponent,
    ConnectionCountComponent,
    AddTeamButtonComponent,
    OurPartnerComponent,


    EditMeetingComponent,
    DeleteMeetingComponent,
    MeetingDetailsModalComponent,
    RecommendedInvestorsComponent,
    RecommendedStartupsComponent,
    DashboardRecentChallengesComponent,
    DashboardRecentJobApplicationsComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
