import { PitchType } from "src/cometchat-pro-angular-ui-kit/CometChatWorkspace/src/utils/enums";
import { IFinancialDetails } from "./financial-details.model";
import { IPitchDeck } from "./pitch-deck-recorder.model";
import { ISupportingDocuments } from "./pitch-deck.model";
import { IProductInfo } from "./product-information.model";
import { IIndustry, IPublicSettings, ITechnology } from "./public.models";

export interface IStartUpInfoBase {
  companyName: string;
  yearOfIncorporation: number;
  registeredCountry: string;
  website: string;
  displayWebsite?: string;
  corporateVideo: string;
  advisoryBoard: string;
  uuid?: string;
  registeredCity?: string;
  isNotRegistered?: boolean;
  isApproved?: boolean;
}

export interface IStartUpPitchDeck {
  uuid: string;
  elevatorPitch: string;
  pitchDocument: string;
  pitchDocumentImages: string[];
  fileName: string;
  pitchType: PitchType;
  powerPitchUrl?: any;
  powerPitchDeckUrl: string;
  uploadPitchUrl: string;
  salesPitchType?: any;
  salesPowerPitchUrl?: any;
  salesPowerPitchDeckUrl?: any;
  salesUploadPitchUrl?: any;
  salesPitchDocument?: any;
  salesPitchDocumentImages?: any;
  salesPitchFileName?: any;
  hiringPitchType?: any;
  hiringPowerPitchUrl?: any;
  hiringPowerPitchDeckUrl?: any;
  hiringUploadPitchUrl?: any;
  hiringPitchDocument?: any;
  hiringPitchDocumentImages?: any;
  hiringPitchFileName?: any;
  servicesLookingFor?: Array<ServicesLookingFor>
}

export enum ServicesLookingFor {
  fundraising = 'fundraising', tech_hiring = 'tech_hiring', customer_access = 'customer_access', business_services = 'business_services'
}


export interface IStartUpInfoFetch extends IStartUpInfoBase {
  companyLogo: string,
  productInformation: IProductInfo,
  financials: IFinancialDetails,
  pitchDeck: IStartUpPitchDeck,
  supportingDocuments: Array<ISupportingDocuments>,
  founders: Array<IFoundersInfo>,
  fundingCommitments: Array<any>,
  startupTechnologies: ITechnology[],
  startupIndustries: IIndustry[],
  startupBusinessModels: IIndustry[]
  servicesLookingFor: Array<ServicesLookingFor>,
  companySize: string,
  hiringContactPersonEmail: string,
  hiringContactPersonMobile: string,
  hiringContactPersonName: string,
  fundingStatus: string
}


export interface IFoundersInfo {
  name: string;
  linkedinUrl: string;
}



export interface ProductStage {
  id: number;
  name: string;
}


export interface Founder {
  uuid: string;
  name: string;
  linkedinUrl: string;
}

export interface User {
  uuid: string;
  accountRole: string;
  name: string;
}

export interface IStartupPublicProfileData {
  uuid: string;
  modifiedAt: Date;
  companyName: string;
  yearOfIncorporation: string;
  registeredCountry: string;
  registeredCity: string;
  website: string;
  displayWebsite?: string;
  advisoryBoard: string;
  corporateVideo?: any;
  companyLogo: string;
  linkedinUrl?: any;
  twitterUrl?: any;
  facebookUrl?: any;
  isApproved: boolean;
  startupTechnologies: IPublicSettings[];
  startupIndustries: IPublicSettings[];
  startupBusinessModels: IPublicSettings[];
  servicesLookingFor: string[];
  isProfileClaimed: boolean;
  isNotRegistered: boolean;
  productInformation: IProductInfo;
  financials: IFinancialDetails;
  pitchDeck: IStartUpPitchDeck;
  supportingDocuments: any[];
  founders: Founder[];
  advisoryBoards:Founder[];
  fundingCommitments: any[];
  user: User[];
  startupRatingsCount: number;
  isLiveDeal?: boolean;
}

