import {
  HttpClient,
  HttpErrorResponse
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  Observable,
  throwError
} from "rxjs";
import {
  catchError, map,
} from "rxjs/operators";
import { ToastAlertService } from "src/app/shared/utils/toast.service";
import { IElevatorPitch, ISupportingDocuments } from "../domain/pitch-deck.model";
import { IProfileDetails } from "../domain/profile.model";
import { ApiEndpointService } from "./api-endpoint.service";
import { GlobalService } from "./global.service";

@Injectable({
  providedIn: "root"
})
export class ProfileService {

  constructor(private http: HttpClient, private toastService: ToastAlertService, private globalService: GlobalService) { }

  public getProfileDetails(): Observable<IProfileDetails> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.USER_PROFILE);
    return this.http.get(url).pipe(
      map((response: any) => {
        console.info(`getProfileDetails GET`);
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while fetching profile!'), 'error');
        console.warn(`getProfileDetails Fault( ${fault.error.message} )`);
        return throwError(() => fault);
      })
    );
  }


  public getUserPublicProfile(id: string): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.USER_PUBLIC_PROFILE) + '/'+ id;
    return this.http.get(url).pipe(
      map((response: any) => {
        console.info(`getProfileDetails GET`);
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while fetching profile!'), 'error');
        console.warn(`getUserPublicProfile Fault( ${fault.error.message} )`);
        return throwError(() => fault);
      })
    );
  }

  public updateProfileDetails(payload: Partial<IProfileDetails>): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.USER_PROFILE);
    console.info(`updateProfileDetails info`, payload);
    return this.http.patch(url, payload).pipe(
      catchError((fault: HttpErrorResponse) => {
        console.warn(`updateProfileDetails Info failed( ${fault?.error?.message} )`);
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while updating profile!'), 'error');
        return throwError(() => fault);
      })
    );
  }

  public updateNewsLetter(payload: Partial<IProfileDetails>): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.USER_PROFILE) + '/subscribe-to-newsletter';
    console.info(`updateProfileDetails info`, payload);
    return this.http.patch(url, payload).pipe(
      catchError((fault: HttpErrorResponse) => {
        console.warn(`updateProfileDetails Info failed( ${fault?.error?.message} )`);
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while updating profile!'), 'error');
        return throwError(() => fault);
      })
    );
  }


  public updateSocialDetails(payload: Partial<IProfileDetails>): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.USER_PROFILE) + '/social-links';
    console.info(`updateSocialDetails info`, payload);
    return this.http.patch(url, payload).pipe(
      catchError((fault: HttpErrorResponse) => {
        console.warn(`updateSocialDetails Info failed( ${fault?.error?.message} )`);
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while updating profile!'), 'error');
        return throwError(() => fault);
      })
    );
  }

  public updateNotification(payload: Partial<IProfileDetails>): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.USER_PROFILE) + '/notification-settings';
    console.info(`updateNotification info`, payload);
    return this.http.patch(url, payload).pipe(
      catchError((fault: HttpErrorResponse) => {
        console.warn(`updateNotification Info failed( ${fault?.error?.message} )`);
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while updating notification!'), 'error');
        return throwError(() => fault);
      })
    );
  }

  public uploadProfilePhoto(logo: File): Observable<any> {
    const payload = new FormData();
    payload.append('file', logo);
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.USER_AVTAR);
    console.info(`saving profile photo`, payload);
    return this.http.post(url, payload).pipe(
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while uploading profile photo'), 'error');
        console.warn(`uploadProfilePhoto( ${fault?.error?.message} )`);
        return throwError(() => fault);
      })
    );
  }

}
