import {
  HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
    select,
    Store
} from "@ngrx/store";
import {
    Observable,
    of,
    throwError
} from "rxjs";
import {
  catchError,
    first,
    mergeMap,
} from "rxjs/operators";
import { StorageService } from "src/app/shared/utils/storage.service";
import { ToastAlertService } from "src/app/shared/utils/toast.service";
import { ApiEndpointService } from "../service/api-endpoint.service";
import * as fromState from "../state/auth";

@Injectable()
export class AddTokenHeaderHttpRequestInterceptor implements HttpInterceptor {
    /**
     * Constructor.
     */
    constructor(private store$: Store<any>, private storageService: StorageService, private router: Router, private toastService: ToastAlertService) {
    }

    /**
     * Intercepts all HTTP requests and adds the JWT token to the request's header if the URL
     * is a REST endpoint and not login or logout.
     */
    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const isApiEndpoint: boolean = ApiEndpointService.isApiEndpoint(request.url);
        const isAuthEndpoint: boolean = ApiEndpointService.isAuthEndpoint(request.url);
        const isPublicPageEndpoint: boolean = ApiEndpointService.isPublicPageEndpoint(request.url);
        const isStartupKitEndpoint: boolean = ApiEndpointService.isStartupKitEndpoint(request.url);
        // NOTE: Only add the auth token to non-Auth REST endpoints.
        if (isStartupKitEndpoint || (isApiEndpoint && !isAuthEndpoint && !isPublicPageEndpoint)) {
            return this.addToken(request).pipe(
                first(),
                mergeMap((requestWithToken: HttpRequest<any>) => next.handle(requestWithToken).pipe((
                  catchError((error: HttpErrorResponse) => {
                    if(error.status === 401) {
                      // this.storageService.remove('user');
                      this.storageService.clear();
                      this.toastService.showToast(error.error.message || 'Session expired', 'error')
                      this.router.navigate(['/auth/login']);
                    }
                    if(error.status === 403) {
                      this.toastService.showToast(error.error.message || 'Forbidden', 'error')
                    }
                    return throwError(() => error);
                  })
                )))
            );
        } else {
            return next.handle(request);
        }
    }
    /**
     * Adds the JWT token to the request's header.
     */
    private addToken(request: HttpRequest<any>): Observable<HttpRequest<any>> {
        // NOTE: DO NOT try to immediately setup this selector in the constructor or as an assignment in a
        // class member variable as there's no stores available when this interceptor first fires up and
        // as a result it'll throw a runtime error.
        return this.store$.pipe(
            select(fromState.getToken),
            first(),
            mergeMap((token: string) => {
                if (token) {
                    request = request.clone({
                        headers: request.headers.set("Authorization", `Bearer ${token}`),
                        withCredentials: true
                    });
                }
                return of(request);
            })
        );
    }
}
