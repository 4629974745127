import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ACCOUNT_TYPE } from './core/domain';
import { VerifyEmailComponent } from './modules/auth/pages/verify-email/verify-email.component';
import { PageNotFoundComponent } from './shared/common-components/page-not-found/page-not-found.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { NonAuthGuard } from './shared/guards/non-auth.guard';

const routes: Routes = [
  // {
  //   path: '',
  //   loadChildren: () => import('./modules/public/public.module').then(m => m.PublicModule),
  // },
  {
    path: '',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'startups', // new startup page homev2
    loadChildren: () => import('./modules/home-v2/home-v2.module').then(m => m.HomeV2Module),
  },
  {
    path: 'about-us-v1',
    loadChildren: () => import('./modules/about-us/about-us.module').then(m => m.AboutUsModule),
  },
  {
    path: 'about-us',
    loadChildren: () => import('./modules/about-us-v2/about-us-v2.module').then(m => m.AboutUsV2Module),
  },
  {
    path: 'faqs',
    loadChildren: () => import('./modules/faq/faq.module').then(m => m.FaqModule),
  },
  {
    path: 'investors-v1',
    loadChildren: () => import('./modules/investors-public/investors-public.module').then(m => m.InvestorsPublicModule),
  },
  {
    path: 'investors',
    loadChildren: () => import('./modules/investors-v2/investors-v2.module').then(m => m.InvestorsV2Module),
  },
  {
    path: 'account',
    loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'glossary',
    loadChildren: () => import('./modules/glossary/glossary.module').then(m => m.GlossaryModule)
  },
  {
    path: 'resources',
    loadChildren: () => import('./modules/resources/resources.module').then(m => m.ResourcesModule)
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./modules/contact-us/contact-us.module').then(m => m.ContactUsModule)
  },
  {
    path: 'startups-v1',
    loadChildren: () => import('./modules/startups-public/startups-public.module').then(m => m.StartupsPublicModule)
  },
  {
    path: 'startup-booster-kit',
    loadChildren: () => import('./modules/startup-kit/startup-kit.module').then(m => m.StartupKitModule)
  },
  {
    path: 'page',
    loadChildren: () => import('./modules/info-page/info-page.module').then(m => m.InfoPageModule)
  },

  {
    path: 'investors',
    loadChildren: () => import('./modules/investors/investors.module').then(m => m.InvestorsModule),
    // canActivate: [AuthGuard],
    // data: {
    //   expectedType: [ACCOUNT_TYPE.INVESTOR]
    // }
  },
  {
    path: 'startups',
    loadChildren: () => import('./modules/startups/startups.module').then(m => m.StartupsModule),
    // canActivate: [AuthGuard],
    // data: {
    //   expectedType: [ACCOUNT_TYPE.STARTUP]
    // }
  },

  {
    path: 'corporates',
    loadChildren: () => import('./modules/corporates-home/corporates-home.module').then(m => m.CorporatesHomeModule),
  },
  {
    path: 'corporates',
    loadChildren: () => import('./modules/corporate/corporate.module').then(m => m.CorporateModule),
  },
  {
    path: 'search',
    loadChildren: () => import('./modules/search/search.module').then(m => m.SearchModule)
  },
  {
    path: 'team',
    loadChildren: () => import('./modules/team/team.module').then(m => m.TeamModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'calender',
    loadChildren: () => import('./modules/calender/calender.module').then(m => m.CalenderModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'chat',
    loadChildren: () => import('./modules/chat/chat.module').then(m => m.ChatModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'pitch-deck',
    loadChildren: () => import('./modules/pitch-deck-recorder/pitch-deck-recorder.module').then(m => m.PitchDeckRecorderModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
    canActivate: [NonAuthGuard]
  },
  {
    path: 'errors/404',
    component: PageNotFoundComponent
  },
  {
    path: 'meetings',
    loadChildren: () => import('./modules/meetings/meetings.module').then(m => m.MeetingsModule),
  },
  {
    path: 'notifications',
    loadChildren: () => import('./modules/notifications/notifications.module').then(m => m.NotificationsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'connections-v1',
    loadChildren: () => import('./modules/connections/connections.module').then(m => m.ConnectionsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'connections-v2',
    loadChildren: () => import('./modules/connections-v2/connections-v2.module').then(m => m.ConnectionsV2Module),
    canActivate: [AuthGuard],
  },

  {
    path: 'connections',
    loadChildren: () => import('./modules/connections-v3/connections-v3.module').then(m => m.ConnectionsV3Module),
    canActivate: [AuthGuard],
  },
  {
    path: 'startup-ratings',
    loadChildren: () => import('./modules/startup-ratings/startup-ratings.module').then(m => m.StartupRatingsModule)
  },
  {
    path: 'jobs',
    loadChildren: () => import('./modules/hire/hire.module').then(m => m.HireModule)
  },

  {
    path: 'jobs/:id/details',
    loadChildren: () => import('./modules/job-details/job-details.module').then(m => m.JobDetailsModule)
  },
  {
    path: 'job-interview/:id',
    loadChildren: () => import('./modules/job-interview/job-interview.module').then(m => m.JobInterviewModule)
  },

  {
    path: 'business-services',
    loadChildren: () => import('./modules/business-services/business-services.module').then(m => m.BusinessServicesModule)
  },
  {
    path: 'connection-request',
    loadChildren: () => import('./modules/connection-approve-reject-page/connection-approve-reject-page.module').then(m => m.ConnectionApproveRejectPageModule),
  },
  {
    path: 'media',
    loadChildren: () => import('./modules/media/media.module').then(m => m.MediaModule),
  },
  {
    path: 'partners',
    loadChildren: () => import('./modules/partners/partners.module').then(m => m.PartnersModule)
  },
  {
    path: 'partners',
    loadChildren: () => import('./modules/partners-dashboard/partners-dashboard.module').then(m => m.PartnersDashboardModule)
  },
  {
    path: 'government-grants',
    loadChildren: () => import('./modules/government-grants/government-grants.module').then(m => m.GovernmentGrantsModule)
  },
  {
    path: 'partners/:partnerId/:name',
    loadChildren: () => import('./modules/partners-details/partners-details.module').then(m => m.PartnersDetailsModule)
  },
  {
    path: 'backdoor-login',
    loadChildren: () => import('./modules/admin-actions/admin-actions.module').then(m => m.AdminActionsModule)
  },
  {
    path: 'request/authenticate',
    loadChildren: () => import('./modules/connection-request-action-email/connection-request-action-email.module').then(m => m.ConnectionRequestActionEmailModule)
  },
  { path: 'verify/email/:verificationUid/:emailAddress', component: VerifyEmailComponent },

  {
    path: 'search/jobs',
    loadChildren: () => import('./modules/job-search/job-search.module').then(m => m.JobSearchModule)
  },
  {
    path: 'search/jobs',
    loadChildren: () => import('./modules/job-public-details/job-public-details.module').then(m => m.JobPublicDetailsModule)
  },

  {
    path: 'challenges',
    loadChildren: () => import('./modules/challenges/challenges.module').then(m => m.ChallengesModule)
  },
  {
    path: 'search/challenges',
    loadChildren: () => import('./modules/challenge-search/challenge-search.module').then(m => m.ChallengeSearchModule)
  },
  {
    path: 'search/challenges',
    loadChildren: () => import('./modules/challenge-public-details/challenge-public-details.module').then(m => m.ChallengePublicDetailsModule)
  },
  {
    path: 'challenges/:id/details',
    loadChildren: () => import('./modules/challenge-details/challenge-details.module').then(m => m.ChallengeDetailsModule)
  },
  {
    path: '**',
    redirectTo: '/errors/404'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
