import { Action } from "@ngrx/store";
import { IGlobalSettings } from "../../domain/settings.model";

export enum GlobalActionTypes {
  GetGlobalSettings = "[Global] GetGlobalSettings",
  GetGlobalSettingsSuccess = "[Global] GetGlobalSettingsSuccess",
  GetGlobalSettingsFault = "[Global] GetGlobalSettingsFault",
}

export class GetGlobalSettings implements Action {
  readonly type = GlobalActionTypes.GetGlobalSettings;
}

export class GetGlobalSettingsSuccess implements Action {
  readonly type = GlobalActionTypes.GetGlobalSettingsSuccess;

  constructor(public payload: IGlobalSettings) { }
}

export class GetGlobalSettingsFault implements Action {
  readonly type = GlobalActionTypes.GetGlobalSettingsFault;
  constructor(public payload: string) { }
}

export type GlobalActions =
  | GetGlobalSettings
  | GetGlobalSettingsSuccess
  | GetGlobalSettingsFault;
