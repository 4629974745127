import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { catchError, distinctUntilChanged, map, Observable, tap, throwError } from 'rxjs';
import { ToastAlertService } from 'src/app/shared/utils/toast.service';
import { Approval, InvestorProfileCompleteness, InvestorProfileCompletenessResponse, InvestorTypes } from '../domain';
import { IProfileDetails } from '../domain/profile.model';
import { getProfileData } from '../state/profile';
import { ApiEndpointService } from './api-endpoint.service';
import { GlobalService } from './global.service';
import * as InvestorDashboardAction from 'src/app/core/state/investor-dashboard/investor.dashboard.action';

@Injectable({
  providedIn: 'root'
})
export class InvestorDashboardService {
  profileData: IProfileDetails;

  getProfileCompleteness() {
    console.log('getting completeness', this.profileData.investorType);
    if(this.profileData.investorType === InvestorTypes.INDIVIDUAL) {
      return this.store.dispatch(new InvestorDashboardAction.GetIndividualProfileCompleteness());
    }
    return this.store.dispatch(new InvestorDashboardAction.GetProfileCompleteness());
  }

  constructor(private http: HttpClient, private toastService: ToastAlertService, private globalService: GlobalService, private store: Store) {
    this.store.pipe(select(getProfileData))
    .pipe(distinctUntilChanged()).subscribe((res) => {
      if (res) {
        this.profileData = res;
      }
    });
   }

  public getInvestorDashboard(): Observable<any> {
    console.log("call");
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.INVESTOR_DASHBOARD);
    return this.http.get(url).pipe(
      map((response: any) => {
        console.info(`investorDashboard GET ( Received investorDashboard `);
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while fetching dashboard details!'), 'error');
        console.warn(`investorDashboard( ${fault.error.message} )`);
        return throwError(() => fault);
      })
    );
  }

  public sendApprovalRequest(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.INVESTOR_DASHBOARD_APPROVAL);
    return this.http.patch(url, {}).pipe(
      tap(() => {
        this.getProfileCompleteness()
      }),
      map((response: any) => {
        console.info(` sendApproval investorDashboard GET ( send sendApproval investorDashboard`);
        this.toastService.showToast('Profile approval request sent!');
        return response.message;
      }),
      catchError((fault: HttpErrorResponse) => {
        console.warn(`sendApproval( ${fault.error.message} )`);
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error sending approval request!'), 'error');
        return throwError(() => fault);
      })
    );
  }

  public providingFunds(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.INVESTOR_DASHBOARD_PROVIDING_FUNDS);
    return this.http.patch(url, {}).pipe(
      map((response: any) => {
        console.info(` providingFunds investorDashboard PATCH ( send providingFunds investorDashboard`);
        return response.message;
      }),
      catchError((fault: HttpErrorResponse) => {
        console.warn(`providingFunds( ${fault.error.message} )`);
        return throwError(() => fault);
      })
    );
  }


  public getInvestorCompleteness(): Observable<InvestorProfileCompleteness> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.INVESTOR_PROFILE_COMPLETENESS);
    return this.http.get(url).pipe(
      map((response: any) => {
        console.info(`investorDashboard GET ( Received getinvestorCompleteness `);
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        console.warn(`investorDashboard( ${fault.error.message} )`);
        return throwError(() => fault);
      })
    );
  }

  public getIndividualInvestorCompleteness(): Observable<InvestorProfileCompleteness> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.INDIVIDUAL_INVESTOR_PROFILE_COMPLETENESS);
    return this.http.get(url).pipe(
      map((response: any) => {
        console.info(`getIndividualInvestorCompleteness GET ( Received getinvestorCompleteness `);
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        console.warn(`getIndividualInvestorCompleteness( ${fault.error.message} )`);
        return throwError(() => fault);
      })
    );
  }
}
