import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class JSInjectService {

  private renderer2: Renderer2;
  private targetElement = 'script';
  private targetAttr = 'src';
  private allSuspects: any;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    rendererFactory: RendererFactory2) {
    this.renderer2 = rendererFactory.createRenderer(null, null);
  }

  injectJSFile(fileName:string, srcPath:string) {
    this.allSuspects = document.getElementsByTagName(this.targetElement);
    for (let i = this.allSuspects.length; i >= 0; i--) {
      if (this.allSuspects[i] && this.allSuspects[i].getAttribute(this.targetAttr) !== null
        && this.allSuspects[i].getAttribute(this.targetAttr).indexOf(`${fileName}`) !== -1) {
        this.allSuspects[i].remove();
      }
    }

    const script = this.renderer2.createElement('script');
    script.type = 'text/javascript';
    script.src = srcPath;
    script.id = `${fileName}-Script`;
    this.renderer2.appendChild(this.document.body, script);
  }

  removeJSFile(fileName:string) {
    this.allSuspects = document.getElementsByTagName(this.targetElement);
    for (let i = this.allSuspects.length; i >= 0; i--) {
      if (this.allSuspects[i] && this.allSuspects[i].getAttribute(this.targetAttr) !== null
        && this.allSuspects[i].getAttribute(this.targetAttr).indexOf(`${fileName}`) !== -1) {
        this.allSuspects[i].remove();
      }
    }
  }
}
