import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, map, Observable, tap, throwError } from 'rxjs';
import { Approval, StartupInvestorDashboardResponse } from '../domain';
import { ApiEndpointService } from './api-endpoint.service';
import * as StartupDashBoardActions from "src/app/core/state/startup-dashboard";

@Injectable({
  providedIn: 'root'
})
export class StartupDashboardService {

  constructor(private http: HttpClient, private store: Store) { }

  public getStartUpDashboard(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.STARTUP_DASHBOARD);
    return this.http.get(url).pipe(
      map((response: StartupInvestorDashboardResponse) => {
        console.info(`startupDashboard GET ( Received startupDashboard `);
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        console.warn(`startupDashboard( ${fault.error.message} )`);
        return throwError(() => fault);
      })
    );
  }

  public getStartUpCompleteness(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.STARTUP_PROFILE_COMPLETENESS);
    return this.http.get(url).pipe(
      map((response: StartupInvestorDashboardResponse) => {
        console.info(`startupDashboard GET ( Received getStartUpCompleteness `);
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        console.warn(`startupDashboard( ${fault.error.message} )`);
        return throwError(() => fault);
      })
    );
  }

  public sendApprovalRequest(data: Approval): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.STARTUP_DASHBOARD_APPROVAL);
    const params = data;
    return this.http.patch(url, params).pipe(
      tap(() => this.store.dispatch(new StartupDashBoardActions.GetProfileCompleteness())),
      map((response: any) => {
        console.info(` sendApproval startupDashboard GET ( send sendApproval startupDashboard`);
        return response.message;
      }),
      catchError((fault: HttpErrorResponse) => {
        console.warn(`sendApproval( ${fault.error.message} )`);
        return throwError(() => fault);
      })
    );
  }

  public raisingFunds(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.STARTUP_DASHBOARD_RAISING_FUNDS);
    return this.http.patch(url, {}).pipe(
      map((response: any) => {
        console.info(` raisingFunds startupDashboard PATCH ( send raisingFunds startupDashboard`);
        return response.message;
      }),
      catchError((fault: HttpErrorResponse) => {
        console.warn(`raisingFunds( ${fault.error.message} )`);
        return throwError(() => fault);
      })
    );
  }
}
