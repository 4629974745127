import { PartnersInformation } from '../domain/partners.model';
import { ApiEndpointService } from './api-endpoint.service';


import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import {
  Observable,
  throwError
} from "rxjs";
import {
  catchError,
  distinctUntilChanged,
  map,
  tap
} from "rxjs/operators";
import { ToastAlertService } from "src/app/shared/utils/toast.service";
// import { ApiEndpointService } from "./api-endpoint.service";
import * as ProfileActions from "src/app/core/state/profile/profile.action";
import * as CorporateDashboardAction from 'src/app/core/state/corporate-dashboard/corporate.dashboard.action';
import { getProfileData } from "../state/profile";
import { IProfileDetails } from "../domain/profile.model";
import { GlobalService } from "./global.service";
import { IAddPartnerStartup, PartnerInformationModel } from '../domain';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
interface ContactFormInfo {
  name: string,
  emailAddress: string,
  mobileNumber: string,
  companyName: string,
  website: string
}

@Injectable({
  providedIn: 'root'
})
export class PartnersService {
  profileData: IProfileDetails;

  constructor(private http: HttpClient, private toastService: ToastAlertService, private store: Store, private globalService: GlobalService,
    private router: Router) {
    this.store.pipe(select(getProfileData))
      .pipe(distinctUntilChanged()).subscribe((res) => {
        if (res) {
          this.profileData = res;
        }
      })
  }


  public getPartnersInformationList(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.PARTNERS_INFORMATION);
    return this.http.get(url).pipe(
      map((response: any): PartnersInformation[] => {
        console.info(`Partner Information GET ( Received Partner Information `);
        return response?.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        console.warn(`Partner Information( ${fault.error.message} )`);
        this.toastService.showToast(fault?.error?.message || 'Error while fetching partner information list', 'error');
        return throwError(() => fault);
      })
    )
  }

  public getPartnersInformation(partnersUUID: string): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.PARTNERS_INFORMATION + '/' + partnersUUID);
    return this.http.get(url).pipe(
      map((response: any): PartnersInformation => {
        return response?.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        console.warn(`Partner Information( ${fault.error.message} )`);
        if(fault.status === 404) {
          this.router.navigate(['/partners'])
        }
        this.toastService.showToast(fault?.error?.message || 'Error while fetching partner information', 'error');
        return throwError(() => fault);
      })
    )
  }

  public contactPartners(contactFormData: ContactFormInfo, partnersUUID: string): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.PARTNERS_SUBMISSION + '/' + partnersUUID);
    return this.http.post(url, contactFormData).pipe(
      map((response: any): PartnersInformation => {
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        console.warn(`Partner Information( ${fault.error.message} )`);
        this.toastService.showToast(fault?.error?.message || 'Error while submitting contact data', 'error');
        return throwError(() => fault);
      })
    )
  }


  public getPartnerInformation(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.PARTNER_INFORMATION);
    return this.http.get(url).pipe(
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while getting partner info!'), 'error');
        return throwError(() => fault);
      })
    )
  }


  public addStartup(sttartup: IAddPartnerStartup): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.PARTNER_ADD_STARTUP);
    return this.http.post(url, sttartup).pipe(
      tap(() => {
        // this.getProfileCompleteness()
      }),
      catchError((fault: HttpErrorResponse) => {
        console.warn(`loginFault( ${fault?.error?.message} )`);
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while saving partner startup info!'), 'error');
        return throwError(() => fault);
      })
    );
  }

  public patchPartnerInfo(corporateInfo: PartnerInformationModel): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.PARTNER_INFORMATION);
    return this.http.patch(url, corporateInfo).pipe(
      tap(() => {
        // this.getProfileCompleteness()
      }),
      catchError((fault: HttpErrorResponse) => {
        console.warn(`loginFault( ${fault?.error?.message} )`);
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while saving partner info!'), 'error');
        return throwError(() => fault);
      })
    );
  }

  public uploadLogo(logo: File, refetchProfile = false): Observable<any> {
    const payload = new FormData();
    payload.append('file', logo);
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.PARTNERS_LOGO_UPLOAD);
    console.info(`partner up logo`, payload);
    return this.http.post(url, payload).pipe(
      tap(() => {
        if (refetchProfile) {
          this.store.dispatch(new ProfileActions.GetProfile());
        }
        // this.getProfileCompleteness()
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while uploading logo!'), 'error');
        console.warn(`save coporate Uplogo( ${fault?.error?.message} )`);
        return throwError(() => fault);
      })
    );
  }

  public uploadInviteFile(file: File): Observable<any> {
    const payload = new FormData();
    payload.append('file', file);
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.PARTNERS_INVITE_CSV);
    return this.http.post(url, payload).pipe(
      tap(() => {
        // if (refetchProfile) {
        //   this.store.dispatch(new ProfileActions.GetProfile());
        // }
        // this.getProfileCompleteness()
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while uploading file!'), 'error');
        return throwError(() => fault);
      })
    );
  }


}
