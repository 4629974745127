import { createFeatureSelector, createSelector } from "@ngrx/store";
import { GlobalState } from "./global.reducer";

export const selectGlobalState = createFeatureSelector<GlobalState>("global");

export const getGlobalSettings = createSelector(
  selectGlobalState,
  (state: GlobalState) => state.settings
);

