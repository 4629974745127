
export const TRENDING_STARTUPS = 'Trending Startups <small>(most visited)</small>';
export const RECENTLY_ADDED_STARTUPS = 'Recently Added Startups';
export const RECENT_INVESTORS = 'Recent Added Investors';
export const MONTHLY_INVESTMENTS = 'Monthly Investments';
export const USER_TYPE_STARTUP = 'startup';
export const USER_TYPE_INVESTOR = 'investor';


export enum USER_TYPES {
  STARTUP = 'startup',
  INVESTOR = 'investor',
  OTHER = 'other',
  CORPORATE = 'corporate'
}
