import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe } from './pipe.safehtml';
import { DecodeHtmlStringPipe } from './pipe.decodeHtmlString';
import { NumberFormatterPipe } from './number-formatter.pipe';
import { ImagekitUrlAppenderPipe } from './imagekit-url-appender.pipe';
import { CheckServicePipe } from './check-service.pipe';
import { FullWebsiteURLPipe } from './pipe.getFullWebsiteURL';

@NgModule({
    declarations: [
        SafeHtmlPipe,
        DecodeHtmlStringPipe,
        NumberFormatterPipe,
        ImagekitUrlAppenderPipe,
        CheckServicePipe,
        FullWebsiteURLPipe
    ],
    imports: [
        CommonModule,
    ],
    exports: [SafeHtmlPipe, DecodeHtmlStringPipe, NumberFormatterPipe, ImagekitUrlAppenderPipe, CheckServicePipe, FullWebsiteURLPipe]
})
export class PipesModule { }
