import { Action } from "@ngrx/store";
import {
    Auth,
    LoginCredentials
} from "../../domain";

export enum AuthActionTypes {
    VerifyOtp = "[Auth] VerifyOtp",
    VerifyOtpSuccess = "[Auth] VerifyOtpSuccess",
    VerifyOtpFault = "[Auth] VerifyOtpFault",

    // add register action here

    NavigateToLogin = "[Auth] NavigateToLogin",
    NavigateToRegister = "[Auth] NavigateToRegister",

    ResetAuthError = "[Auth] ResetAuthError",

    LogOut = 'LogOut',
    LogOutSuccess = 'LogOutSuccess'
}

export class VerifyOtp implements Action {
    readonly type = AuthActionTypes.VerifyOtp;

    constructor(public payload: LoginCredentials) {
    }
}

export class VerifyOtpSuccess implements Action {
    readonly type = AuthActionTypes.VerifyOtpSuccess;

    constructor(public payload: Auth) {
    }
}

export class VerifyOtpFault implements Action {
    readonly type = AuthActionTypes.VerifyOtpFault;

    constructor(public payload: string) {
    }
}


export class NavigateToLogin implements Action {
    readonly type = AuthActionTypes.NavigateToLogin;

    constructor() {
    }
}

export class NavigateToRegister implements Action {
    readonly type = AuthActionTypes.NavigateToRegister;

    constructor() {
    }
}

export class ResetAuthError implements Action {
    readonly type = AuthActionTypes.ResetAuthError;

    constructor() {
    }
}

export class LogOut implements Action {
  readonly type = AuthActionTypes.LogOut;
}
export class LogOutSuccess implements Action {
  readonly type = AuthActionTypes.LogOutSuccess;
}

export type AuthActions =
    | VerifyOtp
    | VerifyOtpSuccess
    | VerifyOtpFault
    | NavigateToLogin
    | NavigateToRegister
    | ResetAuthError
    | LogOut
    | LogOutSuccess;
