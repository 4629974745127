import { ACCOUNT_TYPE } from "src/app/core/domain"
import { PitchDocumentTypes } from "src/app/core/domain/pitch-deck.model"
import { ServicesLookingFor } from "src/app/core/domain/startup.model"

interface INavItem {
  id: string, //unique
  title: string,
  children: Array<{ // IMP: should have atlist one item
    title: string,
    route: string
  }>,
  requiredService?: ServicesLookingFor[]
}


export const NavItems: { [key in ACCOUNT_TYPE]: INavItem[] } = {
  [ACCOUNT_TYPE.STARTUP]: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      children: [],
      requiredService: Object.values(ServicesLookingFor)
    },
    {
      id: 'search',
      title: 'Search',
      children: [
        {
          title: 'Investor search',
          route: '/search/investors',
        },
        {
          title: 'corporates search',
          route: '/search/corporates',
        },
        {
          title: 'startups search',
          route: '/search/startups',
        }
      ],
      requiredService: [ServicesLookingFor.fundraising]
    },

    {
      id: 'hire',
      title: 'Hire',
      children: [
        {
          title: 'Hire',
          route: '/jobs',
        }
      ],
      requiredService: [ServicesLookingFor.tech_hiring]
    },
    {
      id: 'calendar',
      title: 'Calendar',
      children: [
        {
          title: 'Calendar',
          route: '/calender',
        }
      ],
      requiredService: Object.values(ServicesLookingFor)
    },

  ],
  [ACCOUNT_TYPE.INVESTOR]: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      children: []
    },
    // {
    //   id: 'messages',
    //   title: 'Messages',
    //   children: [
    //     {
    //       title: 'Messages',
    //       route: '/chat/conversations',
    //     }
    //   ]
    // },

    {
      id: 'invest',
      title: 'Search',
      children: [
        {
          title: 'Investor search',
          route: '/search/investors',
        },
        {
          title: 'corporates search',
          route: '/search/corporates',
        },
        {
          title: 'startups search',
          route: '/search/startups',
        }
      ]
    },

    {
      id: 'calendar',
      title: 'Calendar',
      children: [
        {
          title: 'Calendar',
          route: '/calender',
        }
      ]
    }
  ],

  [ACCOUNT_TYPE.CORPORATE]: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      children: []
    },
    {
      id: 'invest-corporate',
      title: 'Search',
      children: [
        {
          title: 'Investor search',
          route: '/search/investors',
        },
        {
          title: 'corporates search',
          route: '/search/corporates',
        },
        {
          title: 'startups search',
          route: '/search/startups',
        }
      ]
    },
    {
      id: 'challenges',
      title: 'Challenges',
      children: [
        {
          title: 'Challenges',
          route: '/challenges',
        }
      ]
    },

    {
      id: 'calendar',
      title: 'Calendar',
      children: [
        {
          title: 'Calendar',
          route: '/calender',
        }
      ]
    },

  ],

  [ACCOUNT_TYPE.OTHER]: [
    {
      id: 'search',
      title: 'Search',
      children: [
        {
          title: 'Investor search',
          route: '/search/investors',
        },
        {
          title: 'corporates search',
          route: '/search/corporates',
        },
        {
          title: 'startups search',
          route: '/search/startups',
        }
      ],
    },
  ],
  [ACCOUNT_TYPE.JOB_SEEKER]: [
    {
      id: 'search',
      title: 'Search',
      children: [
        {
          title: 'Investor search',
          route: '/search/investors',
        },
        {
          title: 'corporates search',
          route: '/search/corporates',
        },
        {
          title: 'startups search',
          route: '/search/startups',
        }
      ],
    },
  ],


  [ACCOUNT_TYPE.PARTNER]: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      children: [
        {
          title: 'Dashboard',
          route: '/partners/edit/partner-information'
        }
      ]
    },
    {
      id: 'search',
      title: 'Search',
      children: [
        {
          title: 'Investor search',
          route: '/search/investors',
        },
        {
          title: 'corporates search',
          route: '/search/corporates',
        },
        {
          title: 'startups search',
          route: '/search/startups',
        }
      ],
    },
  ],
}

export const PROFILE_NAV_LINKS = {
  [ACCOUNT_TYPE.INVESTOR]: [
    {
      title: 'Organization Details',
      route: '/investors/edit/organization-details'
    },
    {
      title: 'Investment Details',
      route: '/investors/edit/investments-details'
    },
    {
      title: 'Representative Details',
      route: '/investors/edit/representative-details'
    },
  ],
  [ACCOUNT_TYPE.CORPORATE]: [
    {
      title: 'Company Intro',
      route: '/corporates/edit/corporate-intro'
    },
    {
      title: 'Engagement',
      route: '/corporates/edit/engagement'
    },
  ],
  [ACCOUNT_TYPE.PARTNER]: [
    {
      title: 'Organization Information',
      route: '/partners/edit/partner-information'
    },
    {
      title: 'Startups',
      route: '/partners/edit/startups'
    },
  ],
  [ACCOUNT_TYPE.STARTUP]: [
    {
      title: 'Startup Information',
      route: '/startups/edit/startup-information'
    },
    {
      title: 'Team',
      route: '/startups/edit/team'
    },
    {
      title: 'Industry/Technology',
      route: '/startups/edit/industry-technology'
    },
    {
      title: 'Product Information',
      route: '/startups/edit/product-information'
    },
    {
      title: 'Financials',
      route: '/startups/edit/financial-details'
    },
    // {
    //   title: 'Supporting Documents',
    //   route: '/startups/edit/pitch-deck-documents'
    // },
    {
      title: 'Fundraising Pitch',
      route: `/startups/edit/pitch-deck/${PitchDocumentTypes.FUNDRAISING_PITCH}`,
      service: ServicesLookingFor.fundraising
    },
    {
      title: 'Sales Pitch',
      route: `/startups/edit/pitch-deck/${PitchDocumentTypes.SALES_PITCH}`,
      service: ServicesLookingFor.customer_access
    },
    {
      title: 'HR Information',
      route: `/startups/edit/hire`,
      service: ServicesLookingFor.tech_hiring
    },
    // {
    //   title: 'Hiring Pitch',
    //   route: `/startups/edit/pitch-deck/${PitchDocumentTypes.HIRING_PITCH}`,
    // }
  ]
}


export const ACCOUNT_TYPE_TO_DASHBOARD_UTL = {
  [ACCOUNT_TYPE.STARTUP]: '/startups/dashboard',
  [ACCOUNT_TYPE.INVESTOR]: '/investors/dashboard',
  [ACCOUNT_TYPE.CORPORATE]: '/corporates/dashboard',
  [ACCOUNT_TYPE.PARTNER]: '/partners/edit/partner-information',
  [ACCOUNT_TYPE.OTHER]: '/search/startups',
  [ACCOUNT_TYPE.JOB_SEEKER]: '/search/startups',
}


export function getNavItemsByUserType(userType: ACCOUNT_TYPE) {
  switch (userType) {
    case ACCOUNT_TYPE.STARTUP:
      return prepareStartUpRoute();

    case ACCOUNT_TYPE.INVESTOR:
      return prepareInvestorRoute();

    case ACCOUNT_TYPE.CORPORATE:
      return prepareCorporateRoute();


    case ACCOUNT_TYPE.PARTNER:
      return NavItems[ACCOUNT_TYPE.PARTNER];

    default:
      return NavItems[ACCOUNT_TYPE.OTHER];
  }
}


function prepareCorporateRoute() {
  const items = NavItems[ACCOUNT_TYPE.CORPORATE];
  const dashboardRoute = items.find((e) => e.id === 'dashboard');
  dashboardRoute.children = [{
    title: 'Dashboard',
    route: ACCOUNT_TYPE_TO_DASHBOARD_UTL[ACCOUNT_TYPE.CORPORATE]
  }];

  // const profileRoute = items.find((e) => e.id === 'profile');
  // profileRoute.children = PROFILE_NAV_LINKS[ACCOUNT_TYPE.STARTUP];
  return items;
}

function prepareStartUpRoute() {
  const items = NavItems[ACCOUNT_TYPE.STARTUP];
  const dashboardRoute = items.find((e) => e.id === 'dashboard');
  dashboardRoute.children = [{
    title: 'Dashboard',
    route: ACCOUNT_TYPE_TO_DASHBOARD_UTL[ACCOUNT_TYPE.STARTUP]
  }];

  // const profileRoute = items.find((e) => e.id === 'profile');
  // profileRoute.children = PROFILE_NAV_LINKS[ACCOUNT_TYPE.STARTUP];
  return items;
}


function prepareInvestorRoute() {
  const items = NavItems[ACCOUNT_TYPE.INVESTOR];
  const dashboardRoute = items.find((e) => e.id === 'dashboard');
  dashboardRoute.children = [{
    title: 'Dashboard',
    route: ACCOUNT_TYPE_TO_DASHBOARD_UTL[ACCOUNT_TYPE.INVESTOR]
  }];

  // const profileRoute = items.find((e) => e.id === 'profile');
  // profileRoute.children = PROFILE_NAV_LINKS[ACCOUNT_TYPE.INVESTOR];
  return items;
}
