import { AfterViewInit, Component, Renderer2 } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { NgwWowService } from 'ngx-wow';
import { Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import * as GlobalActions from "./core/state/global/global.action";
import * as AdsActions from "./core/state/ads/ads.action";
import * as NotificationsActions from "./core/state/notifications/notifications.action";
import { getLoggedInUser } from './core/state/auth';
import { Auth } from './core/domain';
import { IGlobalSettings } from './core/domain/settings.model';
import { getGlobalSettings } from './core/state/global';
import { Meta } from "@angular/platform-browser";
import { MixpanelService } from './core/service/mixpanel.service';

declare const gtag: Function;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'sanchiconnect-frontend-angular';
  private wowSubscription!: Subscription;
  faqs: any;
  public getLoggedInUserDetails$: Observable<Auth>;
  private destroyed$: Subject<void> = new Subject();
  public globalSettings$: Observable<IGlobalSettings>;


  constructor(
    private router: Router, private wowService: NgwWowService,
    private route: ActivatedRoute,
    private store: Store, private renderer: Renderer2, private metaService: Meta, private mixPanel: MixpanelService) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      gtag('event', 'page_view', {
        page_path: event.urlAfterRedirects
      })
    });

    this.route.queryParamMap.subscribe((query) => {
      if (query) {
        let referralCode = query.get('referralCode');
        if (referralCode) {
          localStorage.setItem('referralCode', referralCode)
        }
      }
    })

    this.getLoggedInUserDetails$ = this.store.pipe(select(getLoggedInUser));
  }

  ngAfterViewInit() {
    let loader = this.renderer.selectRootElement('#pageLoader');
    this.renderer.setStyle(loader, 'display', 'none');
  }
  ngOnInit() {
    this.mixPanel.init('web-visitor');
    this.store.dispatch(new GlobalActions.GetGlobalSettings())
    this.store.dispatch(new AdsActions.GetAds())

    this.globalSettings$ = this.store.pipe(select(getGlobalSettings));

    this.globalSettings$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        // this.titleService.setTitle(res.websiteName);

        // this.metaService.updateTag({ description: res.websiteDescription })
        this.metaService.addTag({
          name: 'keywords',
          content: res.websiteKeywords
        });
      }
    })


    // you can subscribe to WOW observable to react when an element is revealed
    this.wowSubscription = this.wowService.itemRevealed$.subscribe(
      (item: HTMLElement) => {
        // do whatever you want with revealed element
      });
    this.setLoggedInUserDetails();
  }

  setLoggedInUserDetails(): void {
    let notificationsCountInterval = null;
    this.getLoggedInUserDetails$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res?.accessToken) {
        this.store.dispatch(new NotificationsActions.SetNotificationsCount())
        notificationsCountInterval = setInterval(() => {
          this.store.dispatch(new NotificationsActions.SetNotificationsCount())
        }, 30000)
      } else {
        if (notificationsCountInterval) {
          clearInterval(notificationsCountInterval);
        }
      }
    })
  }

  ngOnDestroy() {
    // unsubscribe (if necessary) to WOW observable to prevent memory leaks
    this.wowSubscription.unsubscribe();
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
