import { Action } from "@ngrx/store";
import { IProductInfo } from "../../domain/product-information.model";
import { IProfileDetails } from "../../domain/profile.model";

export enum ProfileActionTypes {
  GetProfile = "[Profile] GetProfile",
  ProfileSuccess = "[Profile] ProfileSuccess",
  ProfileFault = "[Profile] ProfileFault",
  ClearProfile = '[Profile] ClearProfile'
}

export class GetProfile implements Action {
  readonly type = ProfileActionTypes.GetProfile;
}

export class ProfileSuccess implements Action {
  readonly type = ProfileActionTypes.ProfileSuccess;

  constructor(public payload: IProfileDetails) { }
}

export class ProfileFault implements Action {
  readonly type = ProfileActionTypes.ProfileFault;

  constructor(public payload: string) { }
}


export class ClearProfile implements Action {
  readonly type = ProfileActionTypes.ClearProfile;
}

export type ProfileActions =
  | GetProfile
  | ProfileSuccess
  | ProfileFault
  | ClearProfile;
