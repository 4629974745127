import {
    HttpClient,
    HttpErrorResponse
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    Observable,
    throwError
} from "rxjs";
import {
    catchError,
    map
} from "rxjs/operators";
import {
    Auth,
    AuthResponse,
    LoginCredentials,
    OrganizationTypesReponseModel,
    RegisterCredentials,
    VerifyOtpCredentials
} from "../domain";
import { InvestTypesModel, InvestTypesModelResponse } from "../domain/investment-types.model";

import { ApiEndpointService } from "./api-endpoint.service";

@Injectable({
    providedIn: "root"
})
export class GlobalService {
    /**
     * Constructor.
     */
    constructor(private http: HttpClient) {
    }

    getErrorMessage(fault, defaultMsg: string = 'Something went wrong') {
        let msg;
        if (fault?.error?.error) {
            msg = Array.isArray(fault?.error?.error) ? fault?.error?.error?.[0] : fault?.error?.message;
        }
        return msg || defaultMsg;
    }

    /**
     * Attempt to get organization types.
     */
    public getOrganizationTypes(): Observable<any> {
        const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.ORGANIZATION_TYPE);
        return this.http.get(url).pipe(
            map((response: any): OrganizationTypesReponseModel => {
                return response.data
            }),
            catchError((fault: HttpErrorResponse) => {
                return throwError(() => fault);
            })
        );
    }


    public getPartnerLogos(): Observable<any> {
        const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.PRODUCT_LOGOS);
        return this.http.get(url).pipe(
            map((response: any): any => {
                return response.data || [];
            }),
            catchError((fault: HttpErrorResponse) => {
                return throwError(() => fault);
            })
        );
    }


    public getInvesmentTypes(): Observable<any> {
        const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.ORGANIZATION_INVESTMENT_TYPE);
        return this.http.get(url).pipe(
            map((response: any): InvestTypesModel => {
                return response.data
            }),
            catchError((fault: HttpErrorResponse) => {
                return throwError(() => fault);
            })
        );
    }


    public searchLocations(searchTerm: string): Observable<any> {
        const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.LOCATION_SEARCH + searchTerm);
        return this.http.get(url).pipe(
            map((response: any): any => {
                return response.data || [];
            }),
            catchError((fault: HttpErrorResponse) => {
                return throwError(() => fault);
            })
        );
    }

    public govermentGrants(): Observable<any> {
        const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.GOVERMENT_GRANTS);
        return this.http.get(url).pipe(
            map((response: any): any => {
                return response.data || [];
            }),
            catchError((fault: HttpErrorResponse) => {
                return throwError(() => fault);
            })
        );
    }

    public searchSkills(searchTerm: string): Observable<any> {
        const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.SKILLS_SEARCH + searchTerm);
        return this.http.get(url).pipe(
            map((response: any): any => {
                return response.data || [];
            }),
            catchError((fault: HttpErrorResponse) => {
                return throwError(() => fault);
            })
        );
    }

}
