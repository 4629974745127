import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgEventBus } from 'ng-event-bus';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgwWowModule } from 'ngx-wow';
import { SharedModule } from './shared/shared.module';
import { ToastAlertService } from './shared/utils/toast.service';

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ProtectedLayoutFooterComponent } from './layouts/protected/protected-layout-footer/protected-layout-footer.component';
import { ProtectedLayoutHeaderComponent } from './layouts/protected/protected-layout-header/protected-layout-header.component';
import { ProtectedLayoutWrapperComponent } from './layouts/protected/protected-layout-wrapper/protected-layout-wrapper.component';
import { JSInjectService } from './shared/utils/js-inject.service';
import { CoreModule } from './core/core.module';
import { NgxSpinnerModule } from "ngx-spinner";
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { PipesModule } from './shared/pipes/pipes.module';
import * as Sentry from "@sentry/angular";
import { Router } from "@angular/router";
import { PublicLayoutWrapperV2Component } from './layouts/public/public-layout-wrapper-v2/public-layout-wrapper-v2.component';
import { PublicLayoutHeaderV2Component } from './layouts/public/public-layout-header-v2/public-layout-header-v2.component';
import { PublicLayoutFooterV2Component } from './layouts/public/public-layout-footer-v2/public-layout-footer-v2.component';
import { PublicLayoutHeaderComponent } from './layouts/public/public-layout-header/public-layout-header.component';
import { PublicLayoutFooterComponent } from './layouts/public/public-layout-footer/public-layout-footer.component';
import { PublicLayoutWrapperComponent } from './layouts/public/public-layout-wrapper/public-layout-wrapper.component';

@NgModule({
  declarations: [
    AppComponent,
    ProtectedLayoutHeaderComponent,
    ProtectedLayoutWrapperComponent,
    ProtectedLayoutFooterComponent,
    PublicLayoutWrapperV2Component,
    PublicLayoutHeaderV2Component,
    PublicLayoutFooterV2Component,

    PublicLayoutHeaderComponent,
    PublicLayoutFooterComponent,
    PublicLayoutWrapperComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientXsrfModule,
    SweetAlert2Module.forRoot(),
    AppRoutingModule,
    SharedModule,
    NgbModule,
    NgxDropzoneModule,
    NgwWowModule,
    CoreModule,
    NgxSpinnerModule,
    LazyLoadImageModule,
    PipesModule
  ],
  providers: [
    NgEventBus,
    ToastAlertService,
    JSInjectService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor(trace: Sentry.TraceService) { }
}
