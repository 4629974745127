import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { AdViewerComponent } from './ad-viewer.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ImagekitUrlAppenderPipe } from 'src/app/shared/pipes/imagekit-url-appender.pipe';



@NgModule({
  declarations: [
    AdViewerComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    LazyLoadImageModule,
    NgbModule
  ],
  exports: [
    AdViewerComponent,
    PipesModule,
  ],
  providers: [
    ImagekitUrlAppenderPipe
  ]
})
export class AdViewerModule { }
